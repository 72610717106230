import React, { useEffect, useState } from "react";
import { Typography, Box, Switch } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Modal, Button } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import axios, { Axios } from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowMessage } from "../../../redux/slices/notificationSlice";

const CronJobControl = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workReportJobStatus, setWorkReportJobStatus] = useState(false);
  const [taskListJobStatus, setTaskListJobStatus] = useState(false);
  const [reportStatus, setReportStatus] = useState({
    isWorkReportStatus: null,
    isTaskReportStatus: null,
  });

  const rootStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    margin: "auto",
  };

  const switchContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
  };

  async function getCronJobStatus() {
    const url = `${process.env.REACT_APP_NODE_URL}/api/sendmail/getCronJobStatus`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await axios.get(url, config).then((response) => {
      if (response && response.data.isSuccess) {
        console.log(response.data, "myjob stiei");
        setReportStatus({
          isWorkReportStatus: response.data.result.isWorkReportStatus,
          isTaskReportStatus: response.data.result.isTaskReportStatus,
        });
      }
    });
  }
  useEffect(() => {
    getCronJobStatus();
  }, []);
  useEffect(() => {
    if (reportStatus && reportStatus.isWorkReportStatus === 0) {
      setWorkReportJobStatus(false);
    } else if (reportStatus && reportStatus.isWorkReportStatus === 1) {
      setWorkReportJobStatus(true);
    }
    if (reportStatus && reportStatus.isTaskReportStatus === 0) {
      setTaskListJobStatus(false);
    } else if (reportStatus && reportStatus.isTaskReportStatus === 1) {
      setTaskListJobStatus(true);
    }
  }, [reportStatus]);
  async function toggleWorkReportCronJob(status) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/sendmail/toggleWorkReportCronJob`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await axios.post(url, { status: status }, config).then((response) => {
      if (response && response.data.isSuccess) {
      }
    });
  }

  async function toggleTaskListCronJob(status) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/sendmail/toggleTaskListCronJob`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await axios.post(url, { status: status }, config).then((response) => {
      if (response && response.data.isSuccess) {
        dispatch(setShowMessage(response.data.message));
      }
    });
  }

  const handleWorkReportJobChange = (event) => {
    toggleWorkReportCronJob(event.target.checked);
    setWorkReportJobStatus(event.target.checked);
  };

  const handleTaskListJobChange = (event) => {
    toggleTaskListCronJob(event.target.checked);
    setTaskListJobStatus(event.target.checked);
  };

  return (
    <>
      <div className="my-3  d-flex align-items-end justify-content-end">
        <Button
          variant="btn btn-warning mx-1"
          style={{
            width: "70px",
            height: "35px",
            fontSize: "14px",
            borderRadius: "20px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          BACK
        </Button>
      </div>
      <Box sx={rootStyle}>
        <span
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "#333",
            textDecoration: "none",
          }}
        >
          Notification Control
        </span>
        <Box sx={switchContainerStyle}>
          <div
            style={{
              ...switchContainerStyle,
              border: "1px solid #2F8AC7",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Arial, sans-serif",
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "grey",
                textDecoration: "none",
              }}
            >
              Work Report
            </span>
            <Switch
              checked={workReportJobStatus}
              onChange={handleWorkReportJobChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </Box>
        <Box sx={switchContainerStyle}>
          <div
            style={{
              ...switchContainerStyle,
              border: "1px solid #2F8AC7",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Arial, sans-serif",
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "grey",
                textDecoration: "none",
              }}
            >
              Task Report
            </span>
            <Switch
              checked={taskListJobStatus}
              onChange={handleTaskListJobChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CronJobControl;
