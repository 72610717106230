const translations = {
  en: {
    login: "Login",
    username: "Username",
    password: "Password",
    rememberMe: "Remember My Username",
    loginButton: "Log In",
    forgotPassword: "Forgot password?",
    emailemobile: "Enter Email/Mobile Number",
    epassword: "Enter Password",
    home: "Home",
    agencyp: "Agency Profile",
    dashboard: "Dashboard",
    profile: "Profile",
    sale: "Sale",
    enquiry: "Enquiry",
    service: "Service",
    products: "Products",
    sales: "Sales",
    management: "Management",
    manage: "Manage",
    administration: "Administration",
    roles: "Roles",
    users: "Users",
    employee: "Employee",
    configuration: "Configuration",
    Task: "Task",
    AssignTask: "AssignTask",
    TotalEnquiry: "Total Enquiry",
    WorkAssignArea: "Work Assigned Area",
    enquirysources: "Enquirysources",
    enquirysourcesmodel: "enquirysourcesmodel",
    report: "Report",
    firstname: "First Name",
    lastname: "Last Name",
    phoneno: "Phone Number",
    email: "Email",
    product: "Product",
    salesp: "Sales Person",
    enqdate: "Enquiry Date",
    expdelivery: "Expected Delivery",
    district: "District",
    taluka: "Taluka",
    village: "Village",
    sourceofenq: "Source Of Enquiry",
    addenq: "Add Enquiry",
    workassign: "Work Assign",
    emptype: "Employee Type",
    active: "Active",
    usertype: "User Type",
    adduser: "Add User",
    roledes: "Role Discription",
    basicsetup: "BASIC SETUP",
    state: "State",
    agency: "Agency",
    agencysetting: "AGENCY SETTING",
    mastersetting: "MASTER SETTING",
    branch: "Branch",
    category: "Category",
    department: "Department",
    manufacturer: "Manufacturer",
    task: "Task",
    enqsources: "Enquiry Sources",
    part: "PART",
    partlist: "partlist",
    parts: "part",
    tax: "TAX",
    taxs: "Tax",
    name: "Name",
    person: "Person",
    logo: "Logo",
    save: "Save",
    create: "Create",
    cancel: "Cancel",
    download: "Download",
    android: "Android",
    ios: "iOS",
    back: "Back",
    totalenq: "Total Enquiry",
    workassinarea: "Work Assigned Area",
    workreport: "Work Report",
    download: "Download",
    statename: "State Name",
    statedes: "State Discription",
    edit: "Edit",
    delete: "Delete",
    addstate: "Add State",
    back: "BACK",
    close: "Close",
    save: "Save",
    districtname: "District Name",
    adddistrict: "Add District",
    talukaname: "Taluka Name",
    addtaluka: "Add Taluka",
    villagename: "Village Name",
    addvillage: "Add Village",
    name: "Name",
    emailid: "Email Id",
    aaddress: "Address",
    createdon: "Created On",
    code: "Code",
    newbranch: "New Branch",
    branchname: "Branch Name",
    gstno: "G.S.T Number",
    selectstate: "Select State",
    selectdistrict: "Select District",
    selecttaluka: "Select Taluka",
    contactperson: "Contact Person",
    mobileno: "Mobile Number",
    branchaddress: "Branch Address",
    description: "Description",
    cancel: "Cancel",
    editbranch: "Edit Branch",
    addcategory: "Add Category",
    addholiday: "Add Holiday",
    profiles: "Profiles",
    enquiries: "Enquiries",
    mytask: "My Task",
    employees: "Employees",
    logout: "Logout",
    holiday: "Holiday",
    holidayname: "Holiday Name",
    date: "Date",
    editHoliday: "Edit Holiday",
    addleave: "Add Leave",
    myleave: "My leave",
    leavetype: "Leave Type",
    startd: "Start Date",
    endd: "End Date",
    reason: "Reason",
    apply: "Apply",
    tasktype: "Task Type",
    taskcount: "Task Count",
    tasktimep: "Task Time Period",
    workd: "Work Description",
    taskstatus: "Task Status",
    assigntaskmgmt: "Assign Task Management",
    edittaskmgmt: "Edit Task Management",
    message: "Message",
    addmessage: "Add Message",
    editmsg: "Edit Message",
    messageAction: "MessageAction",
    type: "Type",
    dealstage: "Deal Stages",
    customfield:"Custom Field",
    downlodeformate: "Downlode File Formate",
  },
  gj: {
    login: "લોગિન",
    username: "વપરાશકર્તા નામ",
    password: "પાસવર્ડ",
    rememberMe: "મારું વપરાશકર્તા નામ યાદ રાખો",
    loginButton: "લોગીન બટન",
    forgotPassword: "તમે તમારો પાસવર્ડ ભૂલ્યો છો?",
    emailemobile: "ઈમેલ/મોબાઈલ નંબર દાખલ કરો",
    epassword: "પાસવર્ડ દાખલ કરો",
    home: "હોમ ",
    sale: "સેલ",
    dashboard: "ડેશબોર્ડ",
    profile: "પ્રોફાઇલ",
    agencyp: "એજન્સી પ્રોફાઇલ",
    enquiry: "પૂછપરછ",
    service: "સર્વિસ",
    products: "પ્રોડક્ટસ",
    sales: "સેલ્સ",
    management: "મૈનેજમેન્ટ",
    manage: "મૈનેજ",
    administration: "એડમિનિસ્ટ્રેશન",
    roles: "ભૂમિકાઓ",
    users: "યુઝર્સ",
    employee: "કર્મચારી",
    configuration: "કન્ફિગરેસન",
    Task: "કાર્ય",
    AssignTask: "કાર્ય સોંપો",
    TotalEnquiry: "કુલ પૂછપરછ",
    WorkAssignArea: "કાર્ય સોંપાયેલ વિસ્તાર",
    enquirysources: "પૂછપરછ સ્ત્રોતો",
    enquirysourcesmodel: "પૂછપરછ સ્ત્રોત મોડલ",
    partlist: "ભાગ યાદી",
    report: "રિપોર્ટ",
    firstname: "ફર્સ્ટ નેમ",
    lastname: "લાસ્ટ નેમ ",
    phoneno: "ફોન નંબર ",
    email: "ઈમેલ",
    product: "પ્રોડક્ટ",
    salesp: "સેલ્સ મેન ",
    enqdate: "પૂછપરછની તારીખ",
    expdelivery: "અપેક્ષિત ડિલિવરી",
    district: "જિલ્લો",
    taluka: "તાલુકો",
    village: "ગામ",
    sourceofenq: "પૂછપરછનો સ્ત્રોત",
    addenq: "પૂછપરછ ઉમેરો",
    addholiday: "વિશ્રામ દિવસ ઉમેરો",
    workassign: "કામ સોંપવું",
    emptype: "કર્મચારી પ્રકાર",
    active: "સક્રિય",
    usertype: "યુઝર પ્રકાર",
    adduser: "એડ યુઝર",
    roledes: "ભૂમિકા વર્ણન",
    basicsetup: "મૂળભૂત સેટઅપ",
    state: "રાજ્ય",
    agency: "એજન્સી",
    agencysetting: "એજન્સી સેટિંગ",
    mastersetting: "માસ્ટર સેટિંગ",
    branch: "શાખા",
    category: "શ્રેણી",
    department: "વિભાગ",
    manufacturer: "ઉત્પાદક",
    task: "કાર્ય",
    enqsources: "પૂછપરછ સ્ત્રોતો",
    part: "ભાગ",
    parts: "ભાગ",
    tax: "ટેક્સ",
    taxs: "ટેક્સ",
    name: "નામ",
    person: "વ્યક્તિ",
    logo: "લોગો",
    save: "સાચવો",
    create: "બનાવો",
    cancel: "રદ કરો",
    download: "ડાઉનલોડ કરો",
    android: "એન્ડ્રોઇડ",
    ios: "આઈઓએસ",
    back: "પાછા",
    totalenq: "કુલ પૂછપરછ",
    workassinarea: "કાર્ય સોંપાયેલ વિસ્તાર",
    workreport: "કાર્ય અહેવાલ",
    download: "ડાઉનલોડ",
    statename: "રાજ્યનું નામ",
    statedes: "રાજ્ય વર્ણન",
    edit: "એડિટ",
    delete: "ડીલીટ",
    addstate: "રાજ્ય ઉમેરો",
    back: "પાછા",
    close: "બંધ",
    save: "સેવ",
    districtname: "જિલ્લાનું નામ",
    adddistrict: "જિલ્લો ઉમેરો",
    talukaname: "તાલુકાનું નામ",
    addtaluka: "તાલુકા ઉમેરો",
    villagename: "ગામનું નામ",
    addvillage: "ગામ ઉમેરો",
    name: "નામ",
    emailid: "ઈમેલ આઈડી",
    address: "સરનામું",
    createdon: "બનાવેલી તારીખ",
    code: "કોડ",
    newbranch: "નવી શાખા",
    branchname: "શાખાનું નામ",
    gstno: "G.S.T નંબર",
    selectstate: "રાજ્ય પસંદ કરો",
    selectdistrict: "જિલ્લો પસંદ કરો",
    selecttaluka: "તાલુકો પસંદ કરો",
    contactperson: "સંપર્ક વ્યક્તિ",
    mobileno: "મોબાઇલ નંબર",
    branchaddress: "શાખા સરનામું",
    description: "વર્ણન",
    cancel: "રદ કરો",
    editbranch: "શાખા સંપાદિત કરો",
    addcategory: "શ્રેણી ઉમેરો",
    profiles: "પ્રોફાઇલ્સ",
    enquiries: "પૂછપરછો",
    mytask: "મારું કાર્ય",
    employees: "કર્મચારીઓ",
    logout: "લૉગ આઉટ",
    holiday: "વિશ્રામ દિવસ",
    holidayname: "રજાનું નામ",
    date: "તારીખ",
    editHoliday: "રજા સંપાદિત કરો",
    addleave: "રજા ઉમેરો",
    myleave: "મારી રજા",
    leavetype: "રજાનો પ્રકાર",
    startd: "પ્રારંભ તારીખ",
    endd: "અંતિમ તારીખ",
    reason: "કારણ",
    apply: "અરજી કરો",
    tasktype: "કાર્ય પ્રકાર",
    taskcount: "કાર્ય ગણતરી",
    tasktimep: "કાર્ય સમય અવધિ",
    workd: "કામ વર્ણન",
    taskstatus: "કાર્ય સ્થિતિ",
    assigntaskmgmt: "કાર્ય વ્યવસ્થાપન સોંપો",
    edittaskmgmt: "કાર્ય વ્યવસ્થાપન સંપાદિત કરો",
    message: "સંદેશ",
    addmessage: "સંદેશ ઉમેરો",
    messageAction: "સંદેશ ક્રિયા",
    type: "પ્રકાર",
    dealstage: "ડીલ સ્ટેજ",
    customfield: "કસ્ટમ ફીલ્ડ",
    downlodeformate: "ફાઇલ ફોર્મેટ ડાઉનલોડ કરો",
  },
};

export default translations;
