import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { setShowMessage } from "../redux/slices/notificationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addAgencyToDb,
  clearaddaddAgency,
} from "../redux/slices/addagencySlice";
import translations from "../assets/locals/translations";
import { Button } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";
import qTag from "../assets/images/qtag.png";

// import {
//   clearEditagencyData,
//   clearEditagencyState,
//   editagencyUpdateToDb,
// } from "../redux/slices/editAgencySlice";
export default function AddAgency({ workFor }) {
  // const [agencyCreated, setAgencyCreated] = useState(false);
  const [agencyData, setAgencyData] = useState({
    name: "",
    contact: "",
    email: "",
    logo: null,
    whatsapp_appkey: "",
    whatsapp_authkey: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  function clearInpHook() {
    setAgencyData({
      name: "",
      contact: "",
      email: "",
      logo: null,
      whatsapp_appkey: "",
      whatsapp_authkey: "",
    });
    fileInputRef.current.value = "";
  }
  const currentLanguage = useSelector((state) => state.language.language);

  const addAgency = useSelector((state) => state.addAgency.addAgency);

  // const { editagencySliceState } = useSelector(
  //   (state) => state.editAgencyDataState
  // );
  // const editagencyData = useSelector(
  //   (state) => state.editAgencyDataState.editagencyData
  // );

  useEffect(() => {
    if (addAgency.isSuccess) {
      if (addAgency.message.isSuccess) {
        console.log(addAgency, "addAgency");
        dispatch(setShowMessage("Agency is created"));
        dispatch(clearaddaddAgency());
        navigate("/administration/configuration");
        clearInpHook();
        clearaddaddAgency();
        window.location.reload();
      } else {
        dispatch(setShowMessage("Something is wrong"));
      }
    }
  }, [addAgency]);

  const onChangeHandler = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setAgencyData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setAgencyData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("agencyData", agencyData);
    const aname = agencyData.name;
    const acontact = agencyData.contact;
    const aemail = agencyData.email;
    const alogo = agencyData.logo;
    const whatsapp_appkey = agencyData.whatsapp_appkey;
    const whatsapp_authkey = agencyData.whatsapp_authkey;
    const formData = new FormData();
    formData.append("name", aname);
    formData.append("contact", acontact);
    formData.append("email", aemail);
    formData.append("agency", alogo);
    formData.append("appKey", whatsapp_appkey);
    formData.append("authKey", whatsapp_authkey);
    if (
      aname.length > 0 &&
      acontact !== "" &&
      aemail !== "" &&
      alogo !== null
    ) {
      console.log("result save");

      dispatch(addAgencyToDb(formData));
    } else {
      dispatch(setShowMessage("All field must be field"));
    }
  };
  function handlCancel() {
    navigate("/administration/configuration");
    clearInpHook();
  }

  return (
    <div className="addUser myBorder bg-white rounded p-3">
      <main className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-end justify-content-between">
              <h5 className="fw-bold d-flex align-items-start justify-content-start myLabel">
                {/* {translations[currentLanguage].agency}{" "} */}
                Create Agency
              </h5>

              <Button
                variant="btn btn-warning mx-1"
                style={{
                  borderRadius: "20px",
                }}
                onClick={() => {
                  // redirectModal();
                }}
              >
                {translations[currentLanguage].back}
              </Button>
            </div>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="myLabel" htmlFor="name">
                  {translations[currentLanguage].name}
                </label>
                <input
                  value={agencyData.name}
                  className="myInput inputElement form-control"
                  autoComplete="false"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  type="text"
                  name="name"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label className="myLabel" htmlFor="contact">
                  {translations[currentLanguage].person}
                </label>
                <input
                  value={agencyData.contact}
                  className="myInput inputElement form-control"
                  autoComplete="false"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  type="text"
                  name="contact"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="myLabel" htmlFor="email">
                  {translations[currentLanguage].email}
                </label>
                <input
                  value={agencyData.email}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  className="myInput inputElement form-control"
                  autoComplete="false"
                  type="text"
                  name="email"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="logo" className="myLabel">
                  {translations[currentLanguage].logo}
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="logo"
                  ref={fileInputRef}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="d-flex align-items-center justify-content-center mt-3">
              <img
                src={`${process.env.REACT_APP_NODE_URL}/api${agencyData.logo}`}
                alt="Logo"
                className="rounded-circle"
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                  borderRadius: "50%",
                }}
              />
            </div>
            {agencyData.logo && (
              <div className="d-flex align-items-center justify-content-center m-2">
                <button
                  className="border-0 rounded-pill p-1"
                  // onClick={handleDownload}
                >
                  <DownloadIcon />
                  {translations[currentLanguage].download}
                </button>
              </div>
            )}
          </div>
          <div className="row mt-3">
            <div className="d-flex align-items-center">
              <img
                src={qTag}
                alt="logo"
                className="logo-image"
                height={20}
                width={20}
              />
              <h7 className="mx-1">Configure Whatsapp ?</h7>
            </div>
            <div className="col-md-6 mt-2">
              <label className="myLabel" htmlFor="name">
                App Key
              </label>
              <input
                value={agencyData.whatsapp_appkey}
                className="myInput inputElement form-control"
                autoComplete="false"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                type="text"
                name="whatsapp_appkey"
              />
            </div>

            <div className="col-md-6 mt-2">
              <label className="myLabel" htmlFor="contact">
                Auth Key
              </label>
              <input
                value={agencyData.whatsapp_authkey}
                className="myInput inputElement form-control"
                autoComplete="false"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                type="text"
                name="whatsapp_authkey"
              />
            </div>
          </div>

          <section className="d-flex mt-3 flex-column flex-sm-row">
            <button
              className="col-12 col-sm-5 col-lg-2 myBtn py-2"
              onClick={handleSubmit}
              type="button"
            >
              {workFor === "forEdit"
                ? translations[currentLanguage].save
                : translations[currentLanguage].create}
            </button>

            <button
              className="ms-0 ms-sm-3 mt-3 mt-sm-0 col-12 col-sm-5 col-lg-2 myBtn py-2"
              onClick={handlCancel}
              type="button"
            >
              {translations[currentLanguage].cancel}
            </button>
          </section>
        </div>
      </main>
    </div>
  );
}
