import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import translations from "../assets/locals/translations";
import Axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { setShowMessage } from "../redux/slices/notificationSlice";
export default function SendMessages() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    message: "",
  });
  const [selectAll, setSelectAll] = useState(false);
  const currentLanguage = useSelector((state) => state.language.language);
  const redirectModal = () => {
    navigate(-1);
  };
  const [sendMessagesData, setSendMessagesData] = useState([]);
  const workAssignEmployee = location.state?.selectedData;
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Additional logic can be added here if needed
  };

  useEffect(() => {
    setSendMessagesData((prevData) => ({
      ...prevData,
      ...workAssignEmployee,
    }));
  }, [workAssignEmployee]);

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [newCustomerList, setNewCustomerList] = useState({
    customerList: [],
  });
  const onChangeCustomers = (selectedOptions) => {
    setSelectedEmployee(selectedOptions);
  };
  useEffect(() => {
    async function getCustomerList() {
      const url = `${process.env.REACT_APP_NODE_URL}/api/enquiry/get-customers-list`;
      const config = {
        headers: {
          token: localStorage.getItem("rbacToken"),
        },
      };
      await Axios.get(url, config).then((response) => {
        if (response.data) {
          if (response.data.isSuccess) {
            setNewCustomerList((newCustomerList) => ({
              ...newCustomerList,
              ["customerList"]: response.data.result,
            }));
          }
        }
      });
    }
    getCustomerList();
  }, []);
  const handleHeaderCheckboxClick = () => {
    setSelectAll(!selectAll);
  };

  const handleChildCheckboxClick = (itemId) => {
    const updatedRowsData = rowData.map((row) => {
      if (row.id === itemId) {
        return {
          ...row,
          checkbox: !row.checkbox,
        };
      }
      return row;
    });
    setRowData(updatedRowsData);
  };
  async function sendEmail(emailObj) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/send-email`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.post(url, emailObj, config).then((response) => {
      if (response && response.data.isSuccess) {
        dispatch(setShowMessage(response.data.message));
      }
    });
  }
  useEffect(() => {
    if (rowData && rowData.length > 0) {
      // Fetch data for the first page when component mounts
      handlePaginationModelChange({ page: 0, pageSize: 10 });
    }
  }, [rowData]);

  const handlePaginationModelChange = (params) => {
    console.log(params, "currentPage Data");
    const currentPage = params.page;
    const pageSize = params.pageSize;

    // Calculate the start and end indexes for the current page
    const startIndex = currentPage * pageSize;
    console.log(startIndex, "startIndex");
    const endIndex = Math.min(startIndex + pageSize, rowData.length);

    // Slice the rows data to get the data for the current page
    const currentPageData = rowData.slice(startIndex, endIndex);
    setCurrentPageData(currentPageData);
    console.log(currentPageData, "Data for current page");
  };

  const handleSendEmail = () => {
    console.log("Send Mail", currentPageData, formData);
    const emailIds = [];
    // Using Object.keys()
    const keys = Object.keys(currentPageData);
    keys.forEach((key) => {
      const emailObj = currentPageData[key];
      if (emailObj && emailObj.email) {
        emailIds.push(emailObj.email);
      }
    });
    console.log(emailIds, "emials");
    sendEmail({ emailIds, messages: formData.message });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const columns = [
    {
      field: "rowNumber",
      headerName: (
        <Checkbox
          {...label}
          checked={selectAll}
          onClick={handleHeaderCheckboxClick}
        />
      ),
      minWidth: 90,
      // flex: 1,
      renderCell: (params) => (
        <Checkbox
          {...label}
          key={params.row.id}
          checked={params.row.checkbox}
          onClick={() => handleChildCheckboxClick(params.row.id)}
        />
      ),
    },
    {
      field: "first_name",
      headerAlign: "left",
      align: "left",
      headerName: translations[currentLanguage].firstname,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "last_name",
      headerName: translations[currentLanguage].lastname,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: translations[currentLanguage].phoneno,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: translations[currentLanguage].email,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const email = params.row.email === "null" ? "  " : params.row.email;
        return (
          <div className="myBtnForEdit" onClick={() => {}}>
            {email}
          </div>
        );
      },
    },
    {
      field: "category_name",
      headerName: translations[currentLanguage].category,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "product",
      headerName: translations[currentLanguage].product,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sales_person",
      headerName: translations[currentLanguage].salesp,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: translations[currentLanguage].enqdate,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => {
        return `${moment(params.row.date).format("LL")}`;
      },
    },
    {
      field: "delivery_date",
      headerName: translations[currentLanguage].expdelivery,
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${moment(params.row.delivery_date).format("LL")}`;
      },
    },
    {
      field: "district",
      headerName: translations[currentLanguage].district,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "taluka",
      headerName: translations[currentLanguage].taluka,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "village",
      headerName: translations[currentLanguage].village,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "enquiry_source",
      headerName: translations[currentLanguage].sourceofenq,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 180,
      flex: 1,
    },
  ];
  useEffect(() => {
    if (workAssignEmployee) {
      const formattedData = workAssignEmployee.map((item, index) => ({
        ...item,
        id: item.id, // assuming each item has an ID, adjust accordingly
        checkbox: selectAll, // initial checkbox state
      }));
      setRowData(formattedData);
    }
  }, [workAssignEmployee, selectAll]);

  return (
    <div className="addUser  bg-white rounded p-3">
      <main>
        <div className="row m-0">
          <div className="col-6">
            <h5 className="m-0">Send Messages</h5>
          </div>
          <div className="col-6 d-flex align-items-end justify-content-end">
            <Button
              variant="btn btn-warning mx-1"
              style={{
                width: "70px",
                height: "35px",
                fontSize: "14px",
                borderRadius: "20px",
              }}
              onClick={() => {
                redirectModal();
              }}
            >
              {translations[currentLanguage].back}
            </Button>
          </div>
        </div>
        <div className=" row mt-3 m-0">
          {/* <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
                        <label className="myLabel" htmlFor="employee">
                            Customers
                        </label>
                        <Select
                            isMulti
                            options={newCustomerList.customerList.map(user => ({
                                value: user.id,
                                label: `${user.first_name} ${user.last_name}`,
                            }))}
                            onChange={onChangeCustomers}
                            value={selectedEmployee}
                        />

                    </section> */}
          <section className="d-flex mt-3 flex-column col-12  ">
            <label className="myLabel" htmlFor="email">
              {" "}
              {translations[currentLanguage].message}
            </label>
            <textarea
              rows="3"
              className="myInput inputElement"
              autoComplete="false"
              type="text"
              name="message"
              style={{ marginRight: "20px" }}
              value={formData.message}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
          </section>
        </div>
        <div className=" row mt-3 m-0">
          <section className="d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
            <button
              className="col-12 col-sm-5 col-lg-2 myBtnMsg"
              type="button"
              style={{ marginLeft: "0px" }}
            >
              Send WhatsApp Message
            </button>
          </section>
          <section className="d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
            <button
              className="col-12 col-sm-5 col-lg-2 myBtnMsg"
              type="button"
              style={{ marginLeft: "0px" }}
              onClick={handleSendEmail}
            >
              Send Email Message
            </button>
          </section>
        </div>
        <div
          className="tableMenuHover"
          style={{ height: "85vh", width: "100%", marginTop: "100px" }}
        >
          <DataGrid
            rows={rowData}
            columns={columns}
            getRowId={(params) => {
              return params.rowNumber;
            }}
            style={{
              fontFamily: "Poppins",
              padding: 5,
              backgroundColor: "white",
            }}
            className="rounded"
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onPaginationModelChange={handlePaginationModelChange}
            initialState={{
              ...sendMessagesData.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: () => (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>There is no Enquiry with current branch</span>
                </div>
              ),
            }}
            componentsProps={{
              toolbar: {
                position: "right",
                style: { fontFamily: "Poppins", alignSelf: "end" },
              },
            }}
            rowSelection={false}
            autoPageSize={false}
          />
        </div>
      </main>
    </div>
  );
}
