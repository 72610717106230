import React, { useEffect, useState } from "react";
import Checkbox from '@mui/material/Checkbox'
import translations from "../../../assets/locals/translations";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { Tooltip } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AlertDeleteModal from "../../AlertDelete/AlertDeleteModal";
import Axios from "axios";
import { setShowMessage } from "../../../redux/slices/notificationSlice";
const MessagesList = () => {
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [messagesListData, setMessagesListData] = useState([]);
    const currentLanguage = useSelector((state) => state.language.language);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const navigate = useNavigate();
    const [deleteMessagesId, setDeleteMessagesId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [type, setType] = useState(null);
    function editMessages(data) {
        navigate("/administration/configuration/MessagesList/edit", { state: { data: data } });
    }
    async function getMessagesList() {
        const url = `${process.env.REACT_APP_NODE_URL}/api/get-messages-list`;
        const config = {
            headers: {
                token: localStorage.getItem("rbacToken"),
            },
        };

        const response = await Axios.get(url, config);
        if (response.data && response.data.isSuccess) {
            const formattedData = response.data.result.map((messages, index) => ({
                id: messages.id,
                category: messages.category_name,
                action: messages.msg_action,
                type: messages.type,
                message: messages.message,

            }));

            setMessagesListData(formattedData);
        }
    }
    useEffect(() => {
        getMessagesList();
    }, []);
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };
    const handleHeaderCheckboxClick = () => {
        setSelectAll(!selectAll);
    }
    const handleChildCheckboxClick = (itemId) => {
        const updatedRowsData = rowData.map((row) => {
            if (row.id === itemId) {
                return {
                    ...row,
                    checkbox: !row.checkbox,
                };
            }
            return row;
        });
        setRowData(updatedRowsData);
    };

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const columns = [
        {
            field: "id",
            headerName: (
                <Checkbox
                    {...label}
                    checked={selectAll}
                    onClick={handleHeaderCheckboxClick}
                />
            ),
            minWidth: 90,
            renderCell: (params) => (
                <Checkbox
                    {...label}
                    checked={params.row.checkbox}
                    onClick={() => handleChildCheckboxClick(params.row.id)}
                />
            ),
        },

        {
            field: "category",
            headerAlign: "left",
            align: "left",
            headerName: "Category",
            minWidth: 150,

        },
        {
            field: "action",
            headerAlign: "left",
            align: "left",
            headerName: "Action",
            minWidth: 150,

        },
        {
            field: "type",
            headerAlign: "left",
            align: "left",
            headerName: "Type",
            minWidth: 150,

        },
        {
            field: "message",
            headerAlign: "left",
            align: "left",
            headerName: "Messages",
            minWidth: 500,
            flex: 1,
        },
        {
            field: "menu",
            headerName: (
                <FontAwesomeIcon icon={faEllipsisV} style={{ marginRight: "15px" }} />
            ),
            className: "bg-dark",
            sortable: false,
            filterable: false,
            headerAlign: "right",
            align: "right",
            disableColumnMenu: true,
            width: 130,
            // flex: 1,
            position: "sticky",
            renderCell: (params) => (
                <div className="d-flex justify-content-center dotHoverempicon ">
                    <FontAwesomeIcon icon={faEllipsisV} />
                    <div className="expandDiv">
                        <Tooltip title={translations[currentLanguage].edit}>
                            <button
                                className="myActionBtn m-1"
                                onClick={() => {
                                    editMessages(params.row);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                        fillRule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                </svg>
                            </button>
                        </Tooltip>
                        <Tooltip title={translations[currentLanguage].delete}>
                            <button
                                onClick={() => {
                                    deleteActionCall(params.row);
                                }}
                                className="myActionBtn m-1"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    className="bi bi-trash3"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]

    useEffect(() => {
        const rowsData = messagesListData.map((item, index) => ({
            ...item,
            id: index + 1,
            // id: item.id,
            checkbox: selectAll,
        }));
        setRowData(rowsData);
    }, [messagesListData, selectAll]);
    const redirectModal = () => {
        navigate(-1);
    };
    const submitDelete = async () => {
        if (deleteMessagesId) {
            const url = `${process.env.REACT_APP_NODE_URL}/api/delete-messages/${deleteMessagesId}`;
            const config = {
                headers: {
                    token: localStorage.getItem("rbacToken"),
                },
            };
            try {
                const response = await Axios.get(url, config);
                console.log(response, "response.data");
                if (response.data && response.data.isSuccess) {
                    dispatch(setShowMessage("Message Deleted"));
                    getMessagesList();
                    setDisplayConfirmationModal(false);
                } else {
                    console.log(response.data, "false");
                    dispatch(setShowMessage("Failed to delete"));
                }
            } catch (error) {
                console.error("Error while deleting task:", error);
            }
        }
    };
    const deleteActionCall = (data) => {
        setType("messages_delete");
        setDeleteMessagesId(data.id);
        setDeleteMessage(
            `Are You Sure You Want To Delete The Messages '${data.message}'?`
        );
        setDisplayConfirmationModal(true);
    };
    return (
        <div className="">
            {/* <NavLink to={/edit-employee}>callme</NavLink > */}
            <div className="my-3  d-flex align-items-end justify-content-end">
                <div
                    onClick={() => {
                        navigate("/administration/configuration/MessagesList/Message");
                    }}
                    className="d-flex align-items-center"
                    type="button"
                >
                    <Tooltip title={translations[currentLanguage].AssignTask}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </Tooltip>
                    <Button
                        variant="btn btn-warning"
                        style={{ width: '70px', height: '35px', fontSize: '14px', borderRadius: '20px', marginLeft: '10px' }}
                        onClick={() => {
                            redirectModal();
                        }}
                    >
                        {translations[currentLanguage].back}
                    </Button>
                </div>
            </div>

            <div className="tableMenuHover"
                style={{ height: "85vh", width: "100%" }}>

                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(params) => {
                        return params.id
                    }}
                    className='rounded'
                    style={{ fontFamily: 'Poppins', padding: 5, backgroundColor: 'white', }}
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        ...messagesListData.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    components={{
                        Toolbar: GridToolbar,
                        NoRowsOverlay: () => (
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span>There is no Users with current branch</span>
                            </div>)
                    }}
                    componentsProps={{
                        toolbar: {
                            position: 'right',
                            style: { fontFamily: 'Poppins', alignSelf: 'end' },
                        },
                    }}
                    rowSelection={false}
                    autoPageSize={false}
                />

            </div>
            <AlertDeleteModal
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                type={type}
                id={deleteMessagesId}
                message={deleteMessage}
            />
        </div>
    );
}
export default MessagesList;