import React, { useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/login.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import eyeIconClose from "../assets/images/hide.png";
import eyeIcon from "../assets/images/view.png";
import translations from "../assets/locals/translations";
import Axios from "axios";
import { setShowMessage } from "../redux/slices/notificationSlice";

const OtpVarification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = useSelector((state) => state.language.language);
  const [otp, setOtp] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
   const [showPassword, setShowPassword] = useState(false);
   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { state } = location;
  const loginData = state && state.loginData;

  const [userEmail, setuserEmail] = useState({
    username: loginData?.username || "",
    password: loginData?.password || "",
  });



  const  [resetPassword, setResetPassword] = useState({
    newPassword :"",
    confirmPassword :""
  })

  const handleChange = (newValue) => {
    console.log(newValue, "newValuenewValuenewValuenewValue");
    setOtp(newValue);
  };

  const handleVerify = () => {
    GetOTP();
  };

  const handleOTPSend = async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/api/password-forgot/send-otp`;
      const config = {
        headers: {
          token: localStorage.getItem("rbacToken"),
        },
      };
      const response = await Axios.post(
        url,
        { userEmail: userEmail.username },
        config
      );

      if (response.data?.isSuccess) {
        dispatch(setShowMessage("OTP Send"));
        setCurrentStep(2);
      }
    } catch (error) {
      dispatch(setShowMessage("Failed to Send OTP"));
      console.error("Error sending OTP:", error);
    }
  };

  const GetOTP = async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/api/password-forgot/get-otp-list`;
      const config = {
        headers: {
          token: localStorage.getItem("rbacToken"),
        },
      };
      const response = await Axios.get(url, config);
      if (response.data?.isSuccess) {
        console.log(response.data.result, "get LIST");
        const usersOtpData = response.data.result;
        const UserOtp = usersOtpData.map((item) => ({
          id: item.user_id,
          otp: item.OTP,
        }));
        console.log(UserOtp, "**************");

        const verifyOtp = UserOtp.some((otpdata) => otpdata.otp === otp);
        if (verifyOtp) {
          dispatch(setShowMessage("OTP verification successful"));
          setCurrentStep(3);
        } else {
          dispatch(setShowMessage("OTP verification failed"));
        }
      } else {
        console.error("Error getting OTP list:", response.data?.result);
      }
    } catch (error) {
      console.error("Error getting OTP list:", error.message);
    }
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    console.log("TYGJUKI")
  }
};

  useEffect(() => {
       console.log("Otp mount", otp);
       console.log("Otp mount", userEmail);
  }, [otp, userEmail]);


const handlResetPassword = async()=>{
  try {
      const url = `${process.env.REACT_APP_NODE_URL}/api/password-forgot/reste-password`;
     console.log(url,"%%%%%%%%%%%%")
     const config={
        headers:{
            token:localStorage.getItem("rbacToken")
        },
    };
    const response = await Axios.post(url, { password: resetPassword.confirmPassword,usename:userEmail.username},config)
    console.log(
        response.data?.isSuccess,
        "resetPasswordresetPasswordresetPasswordresetPassword"
        );
           dispatch(setShowMessage("Password Reset successfully"));
     navigate("/login")
    } catch (error) {
      console.error("Error:", error.message);
        dispatch(setShowMessage("Failed to Reset Password"));
    }
}

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-center inner-signin-cover signin-cover-bg">
          <Box className="rounded">
            <div className="column">
              {currentStep === 1 && (
                <Card className="p-5 ">
                  <h2 className="otp_varification">
                    Enter the email/mobile number and we'll send you OTP to
                    reset your password
                  </h2>
                  <section className="mb-3">
                    <label htmlFor="username" className="form-label">
                      {translations[currentLanguage].username}{" "}
                    </label>
                    <input
                      onChange={(e) => {
                        setuserEmail({
                          ...userEmail,
                          username: e.target.value,
                        });
                      }}
                      className="form-control "
                      type="text"
                      id="username"
                      name="username"
                      value={userEmail.username}
                      placeholder="Enter Email/Mobile Number"
                    />
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={handleOTPSend}
                        className="btn btn-block  custom-Otp-btn mt-5"
                        style={{ fontSize: "18px" }}
                        type="button"
                      >
                        Send OTP
                      </button>
                    </div>
                  </section>
                </Card>
              )}
              {currentStep === 2 && (
                <Card className="p-5 ">
                  <h2 className="otp_varification">
                    Please enter the One-Time Password to verify your account!!
                  </h2>
                  <section className="mb-3">
                    <MuiOtpInput value={otp} onChange={handleChange} />
                    <div className="d-flex justify-content-evenly">
                      <button
                        onClick={handleBack}
                        className="btn btn-sm custom-Otp-btn mt-5"
                        style={{ fontSize: "18px" }}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        onClick={handleVerify}
                        className="btn btn-sm custom-Otp-btn mt-5"
                        style={{ fontSize: "18px" }}
                        type="button"
                      >
                        Verify
                      </button>
                    </div>
                  </section>
                </Card>
              )}

              {currentStep === 3 && (
                <Card className="p-5 ">
                  <section className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label login-label"
                    >
                      Enter New Password
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control custom-input"
                        onChange={(e) => {
                          setResetPassword({
                            ...resetPassword,
                            newPassword: e.target.value,
                          });
                        }}
                        onKeyDown={handleKeyDown}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                      />
                      <span
                        className="input-group-text"
                        id="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <img
                          src={showPassword ? eyeIcon : eyeIconClose}
                          alt="Toggle Password"
                          height={20}
                          width={20}
                        />
                      </span>
                    </div>
                  </section>
                  <section className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label login-label"
                    >
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control custom-input"
                        onChange={(e) => {
                          setResetPassword({
                            ...resetPassword,
                            confirmPassword: e.target.value,
                          });
                        }}
                        onKeyDown={handleKeyDown}
                        type={showConfirmPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                      />
                      <span
                        className="input-group-text"
                        id="toggle-password"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        <img
                          src={showConfirmPassword ? eyeIcon : eyeIconClose}
                          alt="Toggle Password"
                          height={20}
                          width={20}
                        />
                      </span>
                    </div>
                  </section>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={handlResetPassword}
                      className="btn btn-block  custom-Otp-btn"
                      style={{ fontSize: "18px" }}
                      type="button"
                    >
                      Reset
                    </button>
                  </div>
                </Card>
              )}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default OtpVarification;
