import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  getUserListFromDb,
  clearUserListState,
} from "../redux/slices/getUserListSlice";
import { setEditUserData } from "../redux/slices/editUserDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { setShowMessage } from "../redux/slices/notificationSlice";
import AlertDeleteModal from "./AlertDelete/AlertDeleteModal";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip } from "@mui/material";
import edit from "../assets/images/editu.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "axios";
import translations from "../assets/locals/translations";
import "../styles/Users.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Select from "react-select";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton"; // import Material-UI's IconButton
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

export default function CallReport() {
  const [task, setTask] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListState = useSelector(
    (state) => state.getUserListSlice.userListState
  );
  const [selectAll, setSelectAll] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const currentLanguage = useSelector((state) => state.language.language);

  const handleHeaderCheckboxClick = () => {
    console.log(!selectAll, "selectAll");
    setSelectAll(!selectAll);
  };

  const getEmployeeList = async () => {
    const url = `${process.env.REACT_APP_NODE_URL}/api/employees/get-employee-list`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data && response.data.isSuccess) {
          console.log(response.data.result, "resultemploee");
          setEmployeeData(response.data.result);
        }
      }
    });
  };
  const employeeList =
    employeeData?.map((item) => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item.id,
    })) || [];
  const onchangeEmployeeHandler = (selectedOptions) => {
    const employeeIds = selectedOptions.map((val) => val.value);
    console.log(employeeIds);
    setSelectedEmployee(employeeIds);
  };
  async function getEmployeeTrackReport() {
    const url = `${process.env.REACT_APP_NODE_URL}/api/get-employee-track-report`;
    const data = {
      selectedDate: selectedDate,
      timeFrom: timeFrom,
      timeTo: timeTo,
      selectedEmployee: selectedEmployee,
    };
    let input_data = JSON.stringify(data);
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.post(url, { input_data }, config).then((response) => {
      if (response.data) {
        if (response.data.isSuccess) {
          console.log("employee track report", response.data.result);
          setTask(response.data.result);
        }
      }
    });
  }
  useEffect(() => {
    getEmployeeList();
  }, []);
  const handleChildCheckboxClick = (itemId) => {
    const updatedRowsData = rowData.map((row) => {
      if (row.id === itemId) {
        return {
          ...row,
          checkbox: !row.checkbox,
        };
      }
      return row;
    });
    console.log(updatedRowsData, "updatedRowsData");
    setRowData(updatedRowsData);
  };
  const handleTrackReport = async () => {
    console.log("report fetching...");
    console.log(
      selectedDate,
      timeFrom,
      timeTo,
      selectedEmployee,
      "handletrackreport"
    );
    getEmployeeTrackReport();
  };
  const formatDate = (startdate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(startdate).toLocaleDateString(undefined, options);
  };
  const handleIncompleteCallDetails = (data) => {
    console.log(data, "dtasKdk");
    navigate("/administration/report/callreport/incompletecalldetails", {
      state: { taskdata: data },
    });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const columns = [
    {
      field: "rowNumber",
      headerName: (
        <Checkbox
          {...label}
          checked={selectAll}
          onClick={handleHeaderCheckboxClick}
        />
      ),
      minWidth: 90,
      renderCell: (params) => (
        <Checkbox
          {...label}
          checked={params.row.checkbox}
          onClick={() => handleChildCheckboxClick(params.row.id)}
        />
      ),
    },
    {
      field: "employee",
      headerAlign: "center",
      align: "center",
      headerClassName: "custom-header",
      headerName: "Employee/Staff",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.employee ? params.row.employee : "-"}`;
      },
    },
    {
      field: "date",
      headerAlign: "left",
      align: "left",
      headerClassName: "custom-header",
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.date
            ? `${moment(params.row.date).format("DD/MM/YYYY")}`
            : "-"
        }`;
      },
    },
    {
      field: "time",
      headerAlign: "left",
      align: "left",
      headerClassName: "custom-header",
      headerName: "Time",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.time
            ? `${moment(
                params.row.time,
                "HH:mm:ss"
              ).format("h:mm:ss A")}`
            : "-"
        }`;
      },
    },
    {
      field: "current_location",
      headerAlign: "left",
      align: "left",
      headerClassName: "custom-header",
      headerName: "Location",
      minWidth: 300,
      flex: 1.2,
      valueGetter: (params) => {
        return `${params.row.task_name ? params.row.task_name : "-"}`;
      },
    },
    {
      field: "status",
      headerAlign: "left",
      align: "left",
      headerClassName: "custom-header",
      headerName: "Status",
      minWidth: 200,
      flex: 1.2,
      valueGetter: (params) => {
        return `${params.row.period_name ? params.row.period_name : "-"}`;
      },
    },
    {
      field: "period_name",
      headerAlign: "left",
      align: "left",
      headerClassName: "custom-header",
      headerName: "Action",
      minWidth: 200,
      flex: 1.2,
      valueGetter: (params) => {
        return `${params.row.period_name ? params.row.period_name : "-"}`;
      },
    },
  ];

  useEffect(() => {
    const rowsData = task.map((item, index) => ({
      ...item,
      rowNumber: index + 1,
      checkbox: selectAll,
    }));
    setRowData(rowsData);
  }, [task, selectAll]);

  //   const redirectModal = () => {
  //     navigate(-1);
  //   };
  return (
    <>
      <div className="bg-white p-2">
        <div className="text-secondary font-weight-bold m-2">
          <h6>Search Report</h6>
          <div className="d-flex flex-wrap align-items-center my-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Pick Date"
                  defaultValue={dayjs("2024-01-17")}
                  onChange={(newValue) =>
                    setSelectedDate(newValue.format("YYYY-MM-DD"))
                  }
                />
              </DemoContainer>
              <span className="mx-2">|</span>
              <div className="d-flex align-items-center my-2">
                <DemoContainer components={["MobileTimePicker"]}>
                  <MobileTimePicker
                    label="Time From"
                    defaultValue={dayjs("2022-04-17T15:30")}
                    onChange={(newValue) =>
                      setTimeFrom(newValue.format("HH:mm"))
                    }
                  />
                </DemoContainer>
              </div>
              <span className="mx-2">|</span>
              <div className="d-flex align-items-center my-2">
                <DemoContainer components={["MobileTimePicker"]}>
                  <MobileTimePicker
                    label="Time To"
                    defaultValue={dayjs("2022-04-17T15:30")}
                    onChange={(newValue) => setTimeTo(newValue.format("HH:mm"))}
                  />
                </DemoContainer>
              </div>
              <span className="mx-2">|</span>
              <section
                className="d-flex flex-column col-12 col-sm-6 col-lg-4"
                style={{ zIndex: 1000 }}
              >
                <Select
                  defaultValue={selectedEmployee}
                  onChange={onchangeEmployeeHandler}
                  options={employeeList}
                  isMulti
                  placeholder="Select Employee"
                  styles={{ padding: "10px" }}
                />
              </section>
            </LocalizationProvider>
          </div>
          <div className="my-3 d-flex flex-wrap">
            <div className="mb-2">
              <Button variant="outlined" onClick={handleTrackReport}>
                SEARCH
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  style={{ marginLeft: "10px" }}
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </Button>
            </div>
            <div className="mb-2" style={{ marginLeft: "10px" }}>
              <Button variant="outlined">
                DOWNLOAD
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  style={{ marginLeft: "10px" }}
                  fill="currentColor"
                  className="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
              </Button>
            </div>
          </div>
        </div>

        <div
          className="tableMenuHover"
          style={{ height: "85vh", width: "100%" }}
        >
          <DataGrid
            rows={rowData}
            columns={columns}
            getRowId={(params) => {
              return params.rowNumber;
            }}
            className="rounded"
            style={{
              fontFamily: "Poppins",
              padding: 5,
              backgroundColor: "white",
            }}
            pageSizeOptions={[5, 10, 25]}
            initialState={{
              ...task.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: () => (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>There is no Users/Employee with Track Report</span>
                </div>
              ),
            }}
            componentsProps={{
              toolbar: {
                position: "right",
                style: { fontFamily: "Poppins", alignSelf: "end" },
              },
            }}
            rowSelection={false}
            autoPageSize={false}
          />
        </div>
      </div>
    </>
  );
}
