import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { json, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import Axios from "axios";
import moment from "moment";
export default function AddvsDelivery({ workFor }) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [Data, setData] = useState([]);
  const [newEnquiryList, setNewEnquiryList] = useState({
    listDsp: [],
    listcategory: [],
  });
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [newEnquiryData, setNewEnquiryData] = useState({
    employee: "",
    category: "",
    startdate: new Date(),
    enddate: new Date(),
  });
  const currentBranch = localStorage.getItem("currentDealerId");
  console.log(currentBranch, "currentBranch*******");

  useEffect(() => {
    console.log(Data, "DataDataDataData");
    if (Data.length > 0) {
      const rowsData = Data.map((item, index) => ({
        ...item,
        id: index + 1,
        rowNumber: index + 1,
      }));
      setRowData(rowsData);
    }
  }, [Data]);
  const getDspList = (currentBranch) => {
    const url = `${process.env.REACT_APP_NODE_URL}/api/enquiry/get-dsp/${currentBranch}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data.isSuccess) {
          console.log("response.data", response.data);
          setNewEnquiryList((newEnquiryList) => ({
            ...newEnquiryList,
            ["listDsp"]: response.data.result,
          }));
        }
      }
    });
  };
  useEffect(() => {
    getDspList(currentBranch);
    getCategoryData();
  }, []);

  const getCategoryData = () => {
    const url = `${process.env.REACT_APP_NODE_URL}/api/enquiry/get-enquiry-categories`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data.isSuccess) {
          // console.log(response.data.result, "###############################");
          setNewEnquiryList((newEnquiryList) => ({
            ...newEnquiryList,
            ["listcategory"]: response.data.result,
          }));
        }
      }
    });
  };

  const redirectModal = () => {
    navigate(-1);
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "&&&&&&&&&&&&&");
    setNewEnquiryData((newEnquiryData) => ({
      ...newEnquiryData,
      [name]: value,
    }));
  };

  const handleEmployeeChange = (selectedOptions) => {
    setSelectedEmployee(selectedOptions);
  };

  const handleSubmit = () => {
    const { employee, category, startdate, enddate } = newEnquiryData;
    const IDS = selectedEmployee.map((item) => item.value);
    const formattedStartDate = moment(startdate).format("YYYY-MM-DD");
    const formattedEndDate = moment(enddate).format("YYYY-MM-DD");

   const data = {
     idsArray: IDS || null,
     categoryID: category,
     startdate: formattedStartDate,
     enddate: formattedEndDate,
   };
   console.log(data.categoryID, "^^^^^^^^^^^^^^^^^^^^^^^^");

    let input_data = JSON.stringify(data);

    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-add-vs-deliver`;

    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };

    Axios.post(url, { input_data }, config)
      .then((response) => {
        if (response.data && response.data.isSuccess) {
          console.log(response.data.result, "###############################");
          setData(response.data.result);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle error scenarios
      });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const columns = [
    {
      field: "rowNumber",
      headerName: <Checkbox {...label} />,
      minWidth: 90,
      renderCell: (params) => (
        <Checkbox
          {...label}
          key={params.row.id}
          checked={params.row.checkbox}
        />
      )
    },
    {
      field: "salesPerson",
      headerAlign: "left",
      align: "left",
      headerName: "Employee Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "Category",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "newEnquiryCount",
      headerName: "Total Employee New Enquiry",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "totalDeliveryCount",
      headerName: "Total Employee Wise Delivery",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "allUsersNewEnquiryCount",
      headerName: "Total New Enquiry",
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "allUserDeliverCOunt",
      headerName: "Total Delivery",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
    },
  ];

  return (
    <>
      <div className="addemployee  bg-white rounded p-3">
        <main>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="m-0">Add Inquiry v/s Delivery Report</h5>
            <Button
              variant="btn btn-warning mx-1"
              style={{
                width: "70px",
                height: "35px",
                fontSize: "14px",
                borderRadius: "20px",
              }}
              onClick={() => {
                redirectModal();
              }}
            >
              BACK
            </Button>
          </div>
        </main>
        <main className="mt-4">
          <div className="row mt-3 m-0">
            <section className="d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
              <label className="myLabel" htmlFor="branch">
                Select Employee
              </label>
              <Select
                isMulti
                options={newEnquiryList.listDsp.map((person) => ({
                  value: person.id,
                  label: `${person.first_name} ${person.last_name}`,
                }))}
                onChange={handleEmployeeChange}
                value={selectedEmployee}
              />
            </section>
            <section className="d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
              <label className="myLabel" htmlFor="branch">
                Select Category
              </label>
              <select
                className="form-control"
                name="category"
                onChange={handlechange}
              >
                <option value=""> Select Category </option>
                {newEnquiryList.listcategory.map((item) => (
                  <option key={item.id} value={item.id} className="myLabel">
                    {item.category_name}
                  </option>
                ))}
              </select>
            </section>
            <section className="datePicker d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
              <label className="myLabel" htmlFor="branch">
                Select Start Date
              </label>
              <DatePicker
                selected={newEnquiryData.startdate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) =>
                  setNewEnquiryData((newEnquiryData) => ({
                    ...newEnquiryData,
                    ["startdate"]: date,
                  }))
                }
              />
            </section>
            <section className="datePicker d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4">
              <label className="myLabel" htmlFor="branch">
                Select End Date
              </label>
              <DatePicker
                selected={newEnquiryData.enddate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) =>
                  setNewEnquiryData((newEnquiryData) => ({
                    ...newEnquiryData,
                    ["enddate"]: date,
                  }))
                }
              />
            </section>
            <section className="d-flex pt-3 flex-column flex-sm-row">
              <button
                className="col-12 col-sm-5 col-lg-2 myBtn py-2"
                onClick={handleSubmit}
                type="button"
              >
                Submit{" "}
              </button>
            </section>
          </div>
        </main>
      </div>
      <DataGrid
        rows={rowData}
        columns={columns}
        getRowId={(params) => params.rowNumber}
        className="rounded"
        style={{
          fontFamily: "Poppins",
          padding: 5,
          backgroundColor: "white",
          marginTop: 10,
        }}
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>There are no employees with the current branch</span>
            </div>
          ),
        }}
        componentsProps={{
          toolbar: {
            position: "right",
            style: { fontFamily: "Poppins", alignSelf: "end" },
          },
        }}
        rowSelection={false}
        autoPageSize={false}
      />
    </>
  );
}
