import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { Button } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import translations from "../assets/locals/translations";
import moment from "moment";
import DatePicker from "react-datepicker";
export default function DealStages() {
  const navigate = useNavigate()
  const [dealstagesData, setDealStagesData] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [dealerStageId, setDealerStageId] = useState(null);
  const currentLanguage = useSelector((state) => state.language.language);
  const [selectAll, setSelectAll] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [dealStages, setDealStages] = useState({
    listDsp: [],
    listDealStages: [],
    category: [],
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const currentBranch = localStorage.getItem("currentDealerId");
  const onChangeEmployees = (e) => {
    setEmployeeId(e.target.value);
  };

  const onChangeCategory = (e) => {
    setCategoryId(e.target.value);
  }
  const onChangeDealStages = (e) => {
    setDealerStageId(e.target.value);
  }
  const redirectModal = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (currentBranch) {
      async function getDspList() {
        const url = `${process.env.REACT_APP_NODE_URL}/api/employees/get-employee-list`;
        const config = {
          headers: {
            token: localStorage.getItem("rbacToken"),
          },
        };
        await Axios.get(url, config).then((response) => {
          if (response.data) {
            if (response.data.isSuccess) {
              setDealStages((dealStages) => ({
                ...dealStages,
                ["listDsp"]: response.data.result,
              }));

            }
          }
        });
      }
      getDspList();
    }
  }, [currentBranch])
  async function getCategory() {
    const url = `${process.env.REACT_APP_NODE_URL}/api/enquiry/get-enquiry-categories`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response) {

        setDealStages((dealStages) => ({
          ...dealStages,
          ["category"]: response.data.result,
        }));

      }
    });
  }

  async function getDealStages() {

    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-deal-stages`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data.isSuccess) {
          setDealStages((dealStages) => ({
            ...dealStages,
            ["listDealStages"]: response.data.result,
          }));
        }
      }
    });

  }
  async function getEnquiriesFromDealStages(dealerStageId) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-deal-stage-enquiry-list/${dealerStageId}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        setDealStagesData(response.data.result)
      }
    });
  }
  async function getEnquiriesFromDealStagesDatewise(dealerStageId, startDate, endDate) {
    const formattedStartDate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-deal-stage-enquiry-list-datewise/${dealerStageId}/${formattedStartDate}/${formattedEndDate}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        setDealStagesData(response.data.result)
      }
    });
  }

  async function getEnquiriesFromDealStagesemployee(dealerStageId, employeeId) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-enquiry-list-by-dealstage-employee/${dealerStageId}/${employeeId}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data.result === undefined) {
          console.log(response.data.result)
        } else {

          setDealStagesData(response.data.result)
        }
      }
    });
  }

  async function getEnquiriesFromDealStagesemployeeDatewise(dealerStageId, employeeId, startDate, endDate) {
    const formattedStartDate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-enquiry-list-by-dealstage-employee-datewise/${dealerStageId}/${employeeId}/${formattedStartDate}/${formattedEndDate}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        if (response.data.result === undefined) {
          console.log(response.data.result)
        } else {

          setDealStagesData(response.data.result)
        }
      }
    });
  }


  async function getEnquiriesFromDealEmployeeCategory(dealerStageId, employeeId, categoryId) {
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-enquiry-list-by-dealstage-employee-category/${dealerStageId}/${employeeId}/${categoryId}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        setDealStagesData(response.data.result)
      }
    });
  }

  async function getEnquiriesFromDealEmployeeCategorydatewise(dealerStageId, employeeId, categoryId, startDate, endDate) {
    const formattedStartDate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const url = `${process.env.REACT_APP_NODE_URL}/api/deal-stage/get-enquiry-list-by-dealstage-employee-category-datewise/${dealerStageId}/${employeeId}/${categoryId}/${formattedStartDate}/${formattedEndDate}`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    await Axios.get(url, config).then((response) => {
      if (response.data) {
        setDealStagesData(response.data.result)
      }
    });
  }
  function handleSubmit() {
    if (dealerStageId && employeeId.length > 0 && startDate && endDate && categoryId) {
      getEnquiriesFromDealEmployeeCategorydatewise(dealerStageId, employeeId, categoryId, startDate, endDate);
    }
    else if (dealerStageId && employeeId.length > 0 && categoryId) {
      getEnquiriesFromDealEmployeeCategory(dealerStageId, employeeId, categoryId);
    }
    else if (dealerStageId && employeeId.length > 0 && startDate && endDate) {
      getEnquiriesFromDealStagesemployeeDatewise(dealerStageId, employeeId, startDate, endDate);
    } else if (dealerStageId && employeeId.length > 0) {
      getEnquiriesFromDealStagesemployee(dealerStageId, employeeId);
    }
    else if (dealerStageId && startDate && endDate) {
      getEnquiriesFromDealStagesDatewise(dealerStageId, startDate, endDate);
    } else if (dealerStageId) {
      getEnquiriesFromDealStages(dealerStageId);
    } else {
      window.alert("Please select deal stages");
    }
  }

  useEffect(() => {
    getCategory();
    getDealStages();
  }, [])

  const handleHeaderCheckboxClick = () => {
    setSelectAll(!selectAll);
  }
  const handleChildCheckboxClick = (itemId) => {
    const updatedRowsData = rowData.map((row) => {
      if (row.id === itemId) {
        return {
          ...row,
          checkbox: !row.checkbox,
        };
      }
      return row;
    });
    setRowData(updatedRowsData);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const columns = [
    {
      field: "rowNumber",
      headerName: (
        <Checkbox
          {...label}
          checked={selectAll}
          onClick={handleHeaderCheckboxClick}
        />
      ),
      minWidth: 90,
      // flex: 1,
      renderCell: (params) => (
        <Checkbox
          {...label}
          key={params.row.id}
          checked={params.row.checkbox}
          onClick={() => handleChildCheckboxClick(params.row.id)}
        />
      ),
    },
    {
      field: "first_name",
      headerAlign: "left",
      align: "left",
      headerName: translations[currentLanguage].firstname,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "last_name",
      headerName: translations[currentLanguage].lastname,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: translations[currentLanguage].phoneno,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: translations[currentLanguage].email,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const email = params.row.email === "null" ? "  " : params.row.email;
        return (
          <div className="myBtnForEdit"
            onClick={() => {

            }}>
            {email}
          </div>
        );
      },
    },
    {
      field: "category_name",
      headerName: translations[currentLanguage].category,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,

    },
    {
      field: "product",
      headerName: translations[currentLanguage].product,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sales_person",
      headerName: translations[currentLanguage].salesp,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: translations[currentLanguage].enqdate,
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => {
        return `${moment(params.row.date).format("LL")}`;
      },
    },
    {
      field: "delivery_date",
      headerName: translations[currentLanguage].expdelivery,
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${moment(params.row.delivery_date).format("LL")}`;
      },
    },
    {
      field: "district",
      headerName: translations[currentLanguage].district,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "taluka",
      headerName: translations[currentLanguage].taluka,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "village",
      headerName: translations[currentLanguage].village,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "enquiry_source",
      headerName: translations[currentLanguage].sourceofenq,
      headerAlign: "left",
      align: "left",
      type: "text",
      minWidth: 180,
      flex: 1,
    },

  ];
  useEffect(() => {
    if (dealstagesData) {
      const rowsData = dealstagesData.map((item, index) => ({
        ...item,
        rowNumber: index, // Use index as a fallback key
        checkbox: selectAll,
      }));
      setRowData(rowsData);
    }
  }, [dealstagesData, selectAll]);

  return (
    <div className='addUser  bg-white rounded p-3'>
      <main>
        <div className="row m-0">
          <div className="col-6">
            <h5 className='m-0 text-secondary' >
              Deal Stages
            </h5>
          </div>
          <div className="col-6 d-flex align-items-end justify-content-end">
            <Button
              variant="btn btn-warning mx-1"
              style={{
                width: '70px',
                height: '35px',
                fontSize: '14px',
                borderRadius: '20px',
              }}
              onClick={() => {
                redirectModal();
              }}
            >
              BACK
            </Button>
          </div>
        </div>

        <div className=' row mt-3 m-0'>
          <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
            <label className="myLabel" >
              Deal Stage
            </label>
            <select onChange={onChangeDealStages} className="myInput" name="dealStages" value={dealerStageId}>
              <option value="" className="myLabel">
                Select Deal Stages
              </option>
              {dealStages.listDealStages &&
                dealStages.listDealStages.length > 0 &&
                dealStages.listDealStages.map((i) => {
                  const listDealStages = `${i.deal_stage}`;
                  return (
                    <option
                      key={i.id}
                      value={i.id}
                      className="myLabel"
                    >
                      {listDealStages}
                    </option>
                  );
                })}
            </select>
          </section>
          <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
            <label className="myLabel" htmlFor="employee">
              Employee List (SSP)
            </label>
            <select onChange={onChangeEmployees} className="myInput" name="employee" value={employeeId}>
              <option value="" className="myLabel">
                Select Employee
              </option>
              {dealStages.listDsp &&
                dealStages.listDsp.length > 0 &&
                dealStages.listDsp.map((i) => {
                  const listDsp = `${i.first_name} ${i.last_name}`;
                  return (
                    <option
                      key={i.id}
                      value={i.id}
                      className="myLabel"
                    >
                      {listDsp}
                    </option>
                  );
                })}
            </select>
          </section>
          <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
            <label className="myLabel" htmlFor="category">
              Category
            </label>
            <select onChange={onChangeCategory} className="myInput" name="category" value={categoryId}>
              <option value="" className="myLabel">
                Select Category
              </option>
              {dealStages.category &&
                dealStages.category.length > 0 &&
                dealStages.category.map((i) => {
                  const category = `${i.category_name}`;
                  return (
                    <option
                      key={i.id}
                      value={i.id}
                      className="myLabel"
                    >
                      {category}
                    </option>
                  );
                })}
            </select>
          </section>
        </div>

        <div className=' row m-0'>
          <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
            <label className="myLabel" htmlFor="startDate">
              {translations[currentLanguage].startd}
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select a date"
              className="myInput inputElement"
            />

          </section>


          <section className='d-flex mt-3 flex-column col-12 col-sm-6 col-lg-4'>
            <label className="myLabel" htmlFor="endDate">
              {translations[currentLanguage].endd}
            </label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select a date"
              className="myInput inputElement"
            />

          </section>

        </div>
      </main>
      <section className="d-flex mt-4 flex-column flex-sm-row">
        <button onClick={handleSubmit}
          className="col-12 col-sm-5 col-lg-2 myBtndeal"
          type="button" style={{ marginLeft: '12px' }}>
          Submit
        </button>
      </section>
      <div
        className="tableMenuHover"
        style={{ height: "85vh", width: "100%", marginTop: "100px" }}
      >
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(params) => {
            return params.rowNumber;
          }}
          style={{
            fontFamily: "Poppins",
            padding: 5,
            backgroundColor: "white",
          }}
          className="rounded"
          pageSizeOptions={[5, 10, 25]}
          initialState={{
            ...dealstagesData.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>There is no Enquiry with current branch</span>
              </div>
            ),
          }}
          componentsProps={{
            toolbar: {
              position: "right",
              style: { fontFamily: "Poppins", alignSelf: "end" },
            },
          }}
          rowSelection={false}
          autoPageSize={false}
        />
      </div>
    </div>
  )
}
