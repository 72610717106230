import React, { useState } from "react";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { setShowMessage } from "../../../redux/slices/notificationSlice";
import { Button } from "react-bootstrap"; // Assuming Button is imported from react-bootstrap

const CustomField = () => {
  const dispatch = useDispatch();

  const [customFields, setCustomFields] = useState([{ label: "", field: "" }]);

  const addCustomField = () => {
    setCustomFields([...customFields, { label: "", field: "" }]);
  };

  const removeCustomField = (indexToRemove) => {
    const updatedFields = customFields.filter(
      (_, index) => index !== indexToRemove
    );
    setCustomFields(updatedFields);
  };

  const handleCustomFieldChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...customFields];
    updatedFields[index][name] = value;
    setCustomFields(updatedFields);
  };

  const handleSubmit = () => {
    const newurl = `${process.env.REACT_APP_NODE_URL}/api/master/add-custom-field`;
    const config = {
      headers: {
        token: localStorage.getItem("rbacToken"),
      },
    };
    Axios.post(newurl, { fields: customFields }, config)
      .then((response) => {
        if (response && response.data.isSuccess) {
          console.log(response.data.result, "add-custom-field");
          setCustomFields(response.data.result);
          dispatch(setShowMessage("Field Created"));
          setCustomFields([{ label: "", field: "" }]); 
        }
      })
  };

  return (
    <div className="addUser myBorder bg-white rounded p-3">
      <div className="row m-0">
        <div className="col-6">
          <h5 className="m-0">Add Custom Field</h5>
        </div>
      </div>
      {customFields && (
        <div className="d-flex flex-row flex-wrap col-12">
          {Array.isArray(customFields) &&
            customFields.map((field, index) => (
              <section className="d-flex mt-3 flex-row " key={index}>
                <div className="d-flex flex-column">
                  <input
                    className="myLabel"
                    onChange={(e) => handleCustomFieldChange(index, e)}
                    placeholder="Enter Custom Field*"
                    style={{ border: "none", outline: "none" }}
                    type="text"
                    name="field"
                    value={field.field}
                  />
                  <input
                    onChange={(e) => handleCustomFieldChange(index, e)}
                    className="myInput inputElement"
                    placeholder="Enter Custom Label"
                    autoComplete="false"
                    type="text"
                    name="label"
                    value={field.label}
                  />
                </div>
                {index === customFields.length - 1 ? (
                  <div className="mx-3 my-3 ">
                    <Button
                      className="btn-lg "
                      variant="dark rounded-circle"
                      onClick={addCustomField}
                    >
                      +
                    </Button>
                  </div>
                ) : (
                  <div className="mx-3 my-3 ">
                    <Button
                      className="btn-lg "
                      variant="danger rounded-circle"
                      onClick={() => removeCustomField(index)}
                    >
                      -
                    </Button>
                  </div>
                )}
              </section>
            ))}
        </div>
      )}

      <section className="d-flex mt-3 flex-column flex-sm-row">
        <button
          className="col-12 col-sm-5 col-lg-2 myBtn py-2"
          onClick={handleSubmit}
          type="button"
        >
          Add Field
        </button>
      </section>
    </div>
  );
};

export default CustomField;
